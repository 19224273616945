import * as API from './'

export default {
    //图形验证码
    getCaptcha: params => {
        return API.POST('api/user/getCaptcha', params)
    },
    //登录手机短信
    RegSmsCode: params => {
        return API.POST('api/user/getUpdateSmsCode', params)
    },
    //注册手机短信
    zcSmscode: params => {
        return API.POST('api/user/getRegSmsCode', params)
    },
    //注册验证api/user/regVerification
    reginfo: params => {
        return API.POST('api/user/regVerification', params)
    },
    //注册
    register: params => {
        return API.POST('api/user/register', params)
    },
    //账号登录
    userLogin: params => {
        return API.POST('api/user/userLogin', params)
    },
    //获取登录验证码
    LoginSmsCode: params => {
        return API.POST('api/user/getLoginSmsCode', params)
    },
    //短信验证码登录
    SMSLogin: params => {
        return API.POST('api/user/SMSLogin', params)
    },
    //忘记密码
    forgetpass: params => {
        return API.POST('api/user/backPWD', params)
    },
    //获取微信二维码
    getLoginQRCodeUrl : params => {
        return API.GET('api/wechat/getLoginQRCodeUrl', params)
    },
     //确认登录
     wxLogin : params => {
        return API.GET('api/wechat/wxLogin', params)
    },
     //绑定微信
     bound : params => {
        return API.GET('api/wechat/bound', params)
    },
     //解绑微信
     removeBound : params => {
        return API.GET('api/wechat/removeBound', params)
    },
}
