<template>
  <div style="display: flex">
    <div class="sidehead">
      <div class="sidebody">
        <div class="sideblack"></div>
        <div class="sidebrown">
          <p>个人设置</p>
        </div>
        <div class="sidebottom">
          <p>PERSONAL SETTINGS</p>
        </div>
        <div
          class="sideall"
          v-for="(item, index) of typelist"
          :key="index"
          @click="change(index, item)"
          :class="active == index ? 'activeClass' : ''"
        >
          <p>{{ item.title }}</p>
          <img v-show="active == index" src="../images/sidemore.png" alt="" />
        </div>
      </div>
    </div>
    <div class="kindright">
      <div class="kindtitle">
        <div class="titleleft">
          <p class="kindline"></p>
          <!-- 获取个人信息接口成功 -->
          <p class="boo">您当前的位置:</p>
          <p class="brownp">首页 >我的图书馆 >个人设置>{{ title }}</p>
        </div>
      </div>
      <div class="rightt" v-show="active == 0">
        <div class="rightop" v-if="perlist">
          <div class="headportrait">
            <img :src="perlist.hasPhoto" alt="" />
          </div>
          <div class="datalist">
            <div class="data">
              <p class="datap">{{ perlist.rdTotalLoanCount || 0 }}</p>
              <p class="datatitle">借阅次数</p>
            </div>
            <div class="data">
              <p class="datap">{{ perlist.rdTotalOrderCount || 0 }}</p>
              <p class="datatitle">预约次数</p>
            </div>
            <div class="data">
              <p class="datap">{{ perlist.rdTotalRenewCount || 0 }}</p>
              <p class="datatitle">续借次数</p>
            </div>
            <div class="data">
              <p class="datap">{{ perlist.rdTotalPeccancy || 0 }}</p>
              <p class="datatitle">违规次数</p>
            </div>
            <div class="data">
              <p class="datap">{{ perlist.rdDebt || 0 }}</p>
              <p class="datatitle">读者欠款</p>
            </div>

            <div class="data">
              <p class="datap">{{ perlist.rdDeposit || 0 }}</p>
              <p class="datatitle">读者押金</p>
            </div>
            <div class="data">
              <p class="datap">{{ perlist.rdScore || 0 }}</p>
              <p class="datatitle">读者积分</p>
            </div>
          </div>
        </div>
        <div class="perinformation">
          <div class="perpall">
            <p class="perp">读者基本信息</p>
            <div class="xiugaibutton">
              <el-button
                @click="modify(perlist)"
                :disabled="
                  scope == 2 ||
                  (phone == perlist.rdPhone &&
                    rdAddress == perlist.rdAddress) ||
                  perlist.rdPhone == '' ||
                  this.perlist.rdAddress == ''
                    ? true
                    : false
                "
                >确认</el-button
              >
            </div>
          </div>
          <p class="perline"></p>
          <div class="perall">
            <div class="inforall">
              <div class="infor" v-if="perlist">
                <div class="zzse">
                  <div class="jiben">读者证号码:{{ perlist.rdid }}</div>
                  <div class="jiben">读者姓名:{{ perlist.rdName }}</div>
                  <div class="jiben">
                    读者证状态:{{
                      perlist.rdcfstate == 1
                        ? "有效"
                        : "" || perlist.rdcfstate == 2
                        ? "验证"
                        : "" || perlist.rdcfstate == 3
                        ? "丢失"
                        : "" || perlist.rdcfstate == 4
                        ? "暂停"
                        : "" || perlist.rdcfstate == 5
                        ? "注销"
                        : ""
                    }}
                  </div>
                  <div class="jiben">读者所属馆:{{ perlist.rdlibName }}</div>
                  <div class="jiben">读者证类型:{{ perlist.rdType }}</div>
                </div>
              </div>
              <div class="readder">
                <!-- 挂失接口500 -->
                <p class="rade">读者证处理:</p>
                <div class="wxread">
                  <button type="text" @click="open(perlist)">
                    {{ guashi }}
                  </button>
                </div>
              </div>
            </div>
            <div class="endxiugia" v-if="perlist">
              <div class="email">
                <!-- 更改地址手机号接口成功 -->
                <p>
                  有效日期:{{ perlist.startDate | formatDate }}~{{
                    perlist.endDate | formatDate
                  }}
                </p>
                <div class="phoneee">
                  <p class="phonee">读者电话:</p>
                  <el-input
                    v-model="perlist.rdPhone"
                    :placeholder="perlist.rdPhone"
                    @input="changevalue($event)"
                  ></el-input>
                </div>
                <p>身份证号:{{ perlist.rdCertify }}</p>
                <p>读者邮箱:{{ perlist.rdEmail }}</p>
                <div class="phoneee">
                  <p class="phonee">通讯地址:</p>
                  <el-input
                    v-model="perlist.rdAddress"
                    :placeholder="perlist.rdAddress"
                    @input="changemail($event)"
                  ></el-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="activepass" v-show="active == 1">
        <!-- 修改密码接口成功 -->
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="当前密码:" prop="nowPwd">
            <el-input
              v-model="ruleForm.nowPwd"
              clearable
              size="small"
              placeholder="请输入当前密码"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码:" prop="newPwd">
            <el-input
              v-model="ruleForm.newPwd"
              clearable
              size="small"
              placeholder="密码长度8-18位必须包含数字和字母"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认新密码:" prop="confirmPwd">
            <el-input
              v-model="ruleForm.confirmPwd"
              clearable
              size="small"
              placeholder="确认新密码"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item style="margin-left: -30px; margin-top: 96px">
            <button @click="submitForm('ruleForm')" class="button">修改</button>
            <button @click="resetForm('ruleForm')" class="butto">取消</button>
          </el-form-item>
        </el-form>
      </div>
      <div class="activepass" v-show="active == 2">
        <iframe
          v-if="wxStauts == 1"
          :src="weChat"
          marginwidth="0"
          framespacing="0"
          marginheight="0"
          frameborder="0"
          width="100%"
          height="500px"
          sandbox="allow-scripts allow-top-navigation allow-same-origin"
          class="middleimg"
        ></iframe>
        <div v-else class="wxrong">
          <p>该账号已绑定</p>
          <button @click="wxCancel">解绑</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../api/api_login";
import Api from "../api/api_information";
export default {
  name: "ClassificationSide",
  data() {
    var validatePass = (rule, valuepass, callback) => {
      if (valuepass === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.confirmPwd !== "") {
          this.$refs.ruleForm.validateField("confirmPwd");
        }
        callback();
      }
    };
    var validatePass2 = (rule, valuepass, callback) => {
      if (valuepass === "") {
        callback(new Error("请再次输入密码"));
      } else if (valuepass !== this.ruleForm.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      wxStauts: "",
      weChat: "",
      event: "",
      phonevent: "",
      scope: 2,
      guashi: "挂失",
      changePwdDialog: false, //修改密码弹框
      ruleForm: {
        newPwd: "",
        confirmPwd: "",
        nowPwd: "",
      },
      rules: {
        nowPwd: [
          { required: true, message: "当前密码不能为空", trigger: "blur" },
          {
            min: 8,
            max: 18,
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,18}$/,
            message: "密码长度8-18位必须包含数字和字母",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
        newPwd: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
          {
            min: 8,
            max: 18,
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,18}$/,
            message: "密码长度8-18位必须包含数字和字母",
            trigger: "blur",
          },
          { validator: validatePass, trigger: "blur" },
        ],
        confirmPwd: [
          { required: true, message: "确认新密码不能为空", trigger: "blur" },
          {
            min: 8,
            max: 18,
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,18}$/,
            message: "密码长度8-18位必须包含数字和字母",
            trigger: "blur",
          },
          { validator: validatePass2, trigger: "blur", required: true },
        ],
      },
      active: 0,
      title: "个人资料",
      headportrait: require("../images/0000.png"),
      typelist: [
        {
          title: "个人资料",
        },
        {
          title: "修改密码",
        },
        {
          title: "绑定微信账号",
        },
      ],
      perlist: null,
      phone: "",
      rdAddress: "",
      phoneButton: "",
      adressButton: "",
    };
  },
  filters: {
    formatDate: function (value) {
      var date = new Date();
      //date.setTime(value);
      var month = date.getMonth() + 1;
      var hours = date.getHours();
      if (hours < 10) hours = "0" + hours;
      var minutes = date.getMinutes();
      if (minutes < 10) minutes = "0" + minutes;
      // var time = date.getFullYear() + "-" + month + "-" + date.getDate() +
      //     " " + hours + ":" + minutes;
      var time = date.getFullYear() + "-" + month + "-" + date.getDate() + " ";
      return time;
    },
  },
  created() {
    this.getWechat();
    Api.getinformation().then((res) => {
      this.perlist = res.data;
      this.phone = this.perlist.rdPhone;
      this.rdAddress = this.perlist.rdAddress;
      this.wxStauts = this.perlist.wechatStatus;
    });
    this.title = "个人资料";
    let token = localStorage.getItem("tokenlogin");
    if (token == null) {
      this.$message("请先登录");
      setTimeout(() => {
        this.$router.push({ path: "/accountlogin" });
      }, 1000);
    }
  },
  methods: {
    //解绑
    unBound() {
      API.removeBound().then((res) => {
        this.$message(res.msg || "解绑成功");
        this.$router.push({ path: "./Retrievalsetting" });
      });
    },
    getWechat() {
      var res = "";
      var chars = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];

      for (var i = 0; i < chars.length; i++) {
        var id = Math.floor(Math.random() * chars.length);
        res += chars[id];
      }
      this.Randnum = res.slice(0, 8);
      API.getLoginQRCodeUrl({
        state: this.Randnum,
      }).then((res) => {
        this.weChat = res.qrConnectUrl;
        this.frameShow = true;
      });
    },
    open(item) {
      this.guashi = "取消挂失";
      this.$prompt("密码:", {
        customClass: "message-logout",
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        // inputPattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,18}$/,
        inputErrorMessage: "密码格式不正确",
      })
        .then(({ value }) => {
          this.guashi = "挂失";
          if (value == null) {
            this.$message({
              message: "密码不为空",
            });
          } else {
            let token = localStorage.getItem("tokenlogin");
            // let token=1   //测试用
            if (token !== null) {
              let params = {
                rdcfstate: item.rdcfstate,
              };
              Api.lost(params).then((res) => {
                if (res.code == 0) {
                  this.$message(res.msg);
                  Api.getinformation().then((res) => {
                    this.perlist = res.data;
                  });
                } else if (res.code == 500) {
                  this.$message(res.msg);
                }
              });
            } else {
              this.guashi = "挂失";
              this.$message("请先登录");
            }
          }
        })
        .catch(() => {
          this.guashi = "挂失";
          // this.$message({
          //   type: "info",
          //   message: "取消输入",
          // });
        });
    },
    submitForm() {
      let token = localStorage.getItem("tokenlogin");
      if (token !== null) {
        //通过token判断
        if (
          this.ruleForm.nowPwd &&
          this.ruleForm.newPwd &&
          this.ruleForm.confirmPwd
        ) {
          let params = {
            rdPasswd: this.ruleForm.nowPwd, //密码
            newPassword: this.ruleForm.newPwd, //新密码
            repetition: this.ruleForm.confirmPwd, //二次确认密码
          };
          Api.modifypass(params).then((res) => {
            if (res.code == 0) {
              this.$message(res.msg);
            } else if (res.code == 500) {
              this.$message(res.msg);
            }
          });
        } else {
          this.$message("请按提示进行输入");
        }
      } else {
        this.$message("请先登录");
      }
    },
    resetForm() {
      this.$message("取消成功");
    },
    classifiy(index, item) {},
    change(index, item) {
      //把index值赋给active，点击改变样式
      this.active = index;
      this.title = item.title;
    },
    changemail($event) {
      this.adressButton = $event;
      if ($event) {
        this.scope = 1;
      } else if (!$event) {
        this.$message({
          type: "success",
          message: "通讯地址不能为空",
        });
      }
    },
    changevalue($event) {
      this.phoneButton = $event;
      if ($event) {
        this.scope = 1;
      } else if (!$event) {
        this.$message({
          message: "读者电话不能为空",
        });
      }
    },
    unBound() {
      API.removeBound().then((res) => {
        this.$message(res.msg || "解绑成功");
        this.$router.push({ path: "./Retrievalsetting" });
        Api.getinformation().then((res) => {
          this.wxStauts = res.data.wechatStatus;
        });
      });
    },
    wxCancel() {
      this.$confirm("是否确认解绑", {
        confirmButtonText: "确定",
        showCancelButton: false,
        confirmButtonClass: "wxcan",
      })
        .then(() => {
          this.unBound();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消解绑",
          });
        });
    },
    modify(item) {
      let token = localStorage.getItem("tokenlogin");
      //  let token=1
      if (token != null) {
        let params = {
          rdName: item.rdName,
          rdPhone: item.rdPhone,
          rdAddress: item.rdAddress,
        };
        Api.modifyinformation(params).then((res) => {
          if (res.code == 0) {
            this.$message(res.msg);
          } else if (res.code == 500) {
            this.$message(res.msg);
          }
        });
      } else {
        this.$message("请先登录");
      }
    },
  },
};
</script>

<style  scoped>
.activeClass p {
  color: #ff9234 !important;
}
.sidehead {
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebody {
  width: 260px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.sideblack {
  width: 260px;
  height: 64px;
  background-color: #424242;
}
.sidebrown {
  width: 180px;
  height: 40px;
  background-color: #ff7701;
  margin: -40px auto;
  text-align: center;
}
.sidebrown p {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 38px;
  letter-spacing: 1px;
  color: #f6f6f6;
  line-height: 6px;
}
.sidebottom {
  width: 180px;
  height: 29px;
  background-color: #ff9234;
  margin: 40px auto;
  text-align: center;
}
.sidebottom p {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  line-height: 1px;
}
.sideall {
  width: 229px;
  display: flex;
  justify-content: space-between;
  height: auto;
  margin: 0 auto;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
}
.sideall img {
  width: 10px;
  height: 18px;
}
.sideall p {
  font-size: 14px;
  color: black;

  margin-left: 25px;
}
.classification {
  width: 229px;
  height: 50px;
  border-bottom: 1px solid #eeeeee;
  text-align: inherit;
  margin: 0 auto;
  font-size: 14px;
}
.rightt {
  width: 100%;
  height: auto;
  margin-top: 20px;
}
.rightop {
  width: 100%;
  height: auto;
  display: flex;
  /* justify-content: space-between; */
}
.headportrait {
  width: 150px;
  height: 200px;
  background-color: #eeeeee;
  border-radius: 10px;
}
.headportrait img {
  width: 150px;
  height: 200px;
  border-radius: 10px;
}
.datalist {
  display: flex;
  margin-left: 29px;
  align-items: center;
  text-align: center;
  width: 700px;
  justify-content: space-between;
}
.datap {
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #ff7701;
}
.datatitle {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 1px;
  color: #000000;
}
.perinformation {
  display: flex;
  margin-top: 46px;
  flex-direction: column;
}
.perp {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #424242;
  margin: 0;
}
.perline {
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
  margin-top: 20px;
}
.jiben {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #666666;
}
.perbutton {
  width: 80px;
  height: 30px;
  background-color: #ff9234;
  border-radius: 5px;
}
.loss {
  width: 80px;
  height: 30px;
  background-color: #ff9234;
  border-radius: 5px;
  text-align: center;
  line-height: 30px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: 10px;
}
.infor {
  /* width: 158px; */
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 9px;
}
.lose {
  display: flex;
}
.readder {
  display: flex;
}
.xiugaibutton button {
  background-color: #ff9234;
  border-radius: 5px;
  color: white;
  text-align: center;
  line-height: 7px;
  margin-top: 9px;
}
.rade {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #666666;
}
.perpall {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.perall {
  width: 862px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.email {
  display: flex;
  flex-direction: column;
}
.email p {
  font-size: 14px;
  font-weight: normal;
  /* letter-spacing: 3px; */
  color: #666666;
}
.phoneee {
  display: flex;
  align-items: center;
}
.phonee {
  width: 78px !important;
}
.activepass {
  margin: 65px auto;
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
}
.newpas {
  width: 400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.newpassword {
  width: 108px;
  text-align: end;
}
.oldd {
  width: 308px;
  display: flex;
  margin: 70px auto;
  justify-content: space-between;
  font-size: 16px;
  color: #ffffff;
}
.olddd {
  width: 130px;
  height: 45px;
  background-color: #ac7345;
  border-radius: 5px;
  text-align: center;
  line-height: 45px;
}
.quxiaoo {
  width: 130px;
  height: 45px;
  background-color: #c3c3c3;
  border-radius: 5px;
  text-align: center;
  line-height: 45px;
}
.boo {
  margin-left: 10px;
  font-size: 14px;
}
.demo-ruleForm {
  width: 516px;
  margin: 0 auto;
}
::v-deep .activepass .el-input {
  width: 315px;
  height: 40px;
}
::v-deep .activepass .el-form-item__label {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}
::v-deep .activepass .el-input__inner {
  height: 40px !important;
}
::v-deep
  .activepass
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  display: none;
}
.activepass .button {
  width: 130px !important;
  height: 45px !important;
  background-color: #ac7345 !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  color: #ffffff !important;
  border: transparent;
}
.activepass .butto {
  width: 130px;
  height: 45px;
  background-color: #c3c3c3;
  border-radius: 5px;
  border: transparent;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  color: #ffffff;
  margin-left: 44px;
}
::v-deep .activepass .el-form-item__label {
  font-size: 16px !important;
}
::v-deep .readder .el-button {
  width: 80px !important;
  height: 30px !important;
  background-color: #ff9234;
  border-radius: 5px;
  color: white;
  text-align: center;
  line-height: 7px;
  margin-top: 9px;
}
.endxiugia {
  width: auto;
  display: flex;
}
.endxiugia .el-button {
  width: 80px;
  height: 30px;
  line-height: 8px;
  background-color: #ac7345;
  border-radius: 5px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  color: #ffffff;
  border: none;
}
.zzse {
  /* width: 157px; */
  height: 200px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.wxrong {
  font-size: 16px;
  width: 280px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #333333;
  font-size: 16px;
  margin-top: -48px;
}
.wxrong button {
  width: 80px !important;
  height: 30px !important;
  background-color: #ff9234;
  border-radius: 5px;
  color: white;
  text-align: center;
  line-height: 7px;
  border: transparent;
}
.wxread {
  font-size: 16px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #333333;
  font-size: 16px;
}
.wxread button {
  margin-left: 5px;
  width: 80px !important;
  height: 30px !important;
  background-color: #ff9234;
  border-radius: 5px;
  color: white;
  text-align: center;
  line-height: 7px;
  border: transparent;
}
</style>
<style >
.wxcan {
  background-color: #ff9234 !important;
  border: transparent !important;
}
.wxrong .el-message-box__headerbtn:hover .el-message-box__close {
  color: #ff9234 !important;
}
</style>
