<template>
  <div class="Retrievaldetails">
    <Headernav></Headernav>
    <Retrievalnav></Retrievalnav>
    <!-- 主体部分 -->
    <div class="banner">
      <RetrievalSide></RetrievalSide>
    </div>
    <el-backtop :visibility-height="100"></el-backtop>
    <Footer></Footer>
  </div>
</template>
<script>
import Api from "../api/api_information";
import Headernav from "../components/headernav.vue";
import Footer from "../components/footer.vue";
import Retrievalnav from "../components/retrievalnav.vue";
import RetrievalSide from "../components/retrievalSide.vue";
export default {
  name: "Retrievaldetails",
  data() {
    return {
   
    };
  },
  components: {
    Headernav,
    Footer,
    Retrievalnav,
    RetrievalSide,
  },
  watch: {},
  methods: {
    
  },
  created(){



  },
};
</script>
<style scope>
.Retrievaldetails {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  
}
.Retrievaldetails .banner {
  width: 1200px;
  min-height: 700px;
  margin: 0 auto;
  display: flex;
}
.kindline {
  width: 3px;
  height: 21px;
  background-color: #ff7701;
}
.titleleft {
  width: 860px;
  display: flex;
  align-items: center;
}
.book {
  margin-left: 10px;
  color: #ff7701;
  font-size: 18px;
  
}
.kindtitle {
  border-bottom: 1px solid #eeeeee;
  height: 58px;
}
.kindright {
  margin-left: 56px;
  display: flex;
  flex-direction: column;
}
.brownp {
  font-size: 14px;
  color: #000;
  
  padding-left: 15px;
}
</style>
