import * as API from '.'
export default {
    // 获取个人信息
    getinformation: params => {
        return API.POST('api/user/getUser',params)
    },
     // 修改用户信息
     modifyinformation: params => {
        return API.POST('api/opac/updateUser',params)
    },
      // 挂失
      lost: params => {
        return API.POST('api/opac/updateUser',params)
    },
      // 修改密码
      modifypass: params => {
        return API.POST('api/opac/changePassword',params)
    },
    
}
